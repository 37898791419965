import { Grid } from "@material-ui/core";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import StarRating from "./reviews/StarRating";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    higherLevelGrids: {
      paddingTop: "0.1rem",
      background: "#4b0340",
      width: "100%",
      height: "2rem",
    },
    buttonGrid: {
      [theme.breakpoints.between(0, 370)]: {
        maxWidth: "33.3333%",
      },
      [theme.breakpoints.up(370)]: {
        maxWidth: "33.33333%",
      },
    },
    icon: {
      color: "white",
      justifyContent: "center",
      fontFamily: "open sans !important",
      fontWeight: 600,
      padding: 0.25,
      [theme.breakpoints.down("xs")]: {
        fontSize: 13,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 16,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 18,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 18,
      },
      "@media (max-width: 320px)": {
        fontSize: "12px !important",
      },
    },
  })
);

export default function AfterCarousel() {
  const classes = useStyles();

  const setFirstGrid = () => {
    return (
      <Grid
        container
        justifyContent="space-around"
        className={classes.higherLevelGrids}
      >
        <Grid
          container
          xs={12}
          justifyContent="center"
          style={{ flexWrap: "nowrap", gap: "3em" }}
        >
          <StarRating
            rating={4.8}
            url="https://www.zorgkaartnederland.nl/zorginstelling/fysiotherapiepraktijk-boost-fysiotherapie-nieuw-vennep-10017653/waardering"
            reviewCount={45}
            icon="https://www.zorgkaartnederland.nl/build/images/elements/logo.svg" // Custom icon URL
          />

          <StarRating
            rating={4.9}
            url="https://www.google.com/search?q=BOOST+Fysio+en+Manuele+Therapie&stick=H4sIAAAAAAAA_-NgU1I1qDAxTzZNNjY2ME5NNjW1SDO2MqhIMTM3SElJNkq1MEg1NDE3WsQq7-TvHxyi4FZZnJmvkJqn4JuYV5qak6oQkpFalFiQmQoAX_cX1UsAAAA&hl=nl&mat=CQPd29BrRReQElYBqiIDwh9Ru_yVVTttCJcN4rkpRCPv0h1tFfXgTc0wnjsxrTdOeYbR7M5EmUAP-iyg2Ml9gbirU4IRe7V4P2tj697CVpyCSNI9MvbDyJV34JYLAcfX4A&authuser=1#mpd=~10382843795293854979/customers/reviews"
            reviewCount={14}
            icon={faGoogle as IconProp} // Pass the Google icon
            iconSize="1x" // Optional: adjust the size of the icon
          />
        </Grid>
      </Grid>
    );
  };

  return <>{setFirstGrid()}</>;
}
