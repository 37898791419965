import {
  CssBaseline,
  Typography,
  Grid,
  Avatar,
  IconButton,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import "../stylesheets/App.css";
import ProfielFoto from "../images/fysiotherapie-nieuw-vennep-Emilie-Wegman-foto.png";
import Copyright from "./Copyright";
import NavigationBar from "../components/NavigationBar";
import TopBar from "../components/TopBar";
import { Call, Language, MailOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icons: {
      color: "#4b0340",
      [theme.breakpoints.down("xs")]: {
        fontSize: 15,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 20,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 20,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 30,
      },
    },
    font: {
      padding: 0,
      color: "#3d072d",
      fontWeight: 600,
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 14,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 16,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 18,
      },
    },
    avatar: {
      [theme.breakpoints.down("sm")]: {
        width: "20rem",
        height: "25rem",
      },
      [theme.breakpoints.up("md")]: {
        width: "25rem",
        height: "30rem",
        marginLeft: "2rem",
      },
      [theme.breakpoints.up("lg")]: {
        width: "30rem",
        height: "30rem",
      },
    },
    boodschap: {
      fontWeight: 400,
      marginTop: "0.5rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
        marginLeft: "2rem",
        marginRight: "2rem",
        textAlign: "left",
        order: 1,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 14,
        marginLeft: "4rem",
        textAlign: "left",
        order: 2,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 16,
        marginLeft: "6rem",
        textAlign: "left",
        order: 2,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 18,
        marginLeft: "6rem",
        textAlign: "left",
        order: 2,
      },
    },
    imageGrid: {
      [theme.breakpoints.down("xs")]: {
        alignItems: "center",
        marginBottom: "1rem",
        order: 1,
      },
      [theme.breakpoints.up("sm")]: {
        alignItems: "center",
        marginBottom: "2rem",
        order: 1,
      },
      [theme.breakpoints.up("md")]: {
        alignItems: "center",
        marginBottom: "2rem",
        order: 2,
      },
      [theme.breakpoints.up("lg")]: {
        alignItems: "flex-start",
        marginBottom: "2rem",
        order: 2,
      },
    },
    infoGrid: {
      [theme.breakpoints.down("xs")]: {
        marginBottom: "2rem",
        order: 2,
      },
      [theme.breakpoints.up("sm")]: {
        marginTop: "2rem",
        marginBottom: "2rem",
        order: 1,
      },
      [theme.breakpoints.up("md")]: {
        marginTop: "2rem",
        marginBottom: "2rem",
        order: 1,
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "2rem",
        marginBottom: "2rem",
        order: 1,
      },
    },
  })
);

function OverDeTherapeut() {
  const classes = useStyles();
  return (
    <div style={{ background: "white !important" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>BOOST Fysiotherapie / Manuele therapie / Dry needling</title>
        <meta
          name="description"
          content="BOOST fysiotherapie is een specialistische praktijk voor fysio en manuele therapie in Nieuw Vennep. De praktijkeigenaar is Emilie Wegman, Manueel therapeut - OMT Orthopedische Manuele Therapie"
        />
        <meta
          property="og:title"
          content="BOOST Fysiotherapie / Manuele therapie / Dry needling"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="/over" />
      </Helmet>

      <CssBaseline />

      <TopBar />
      <NavigationBar />

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: "1rem" }}
        direction="row"
      >
        <Grid
          container
          item
          direction="column"
          xs={12}
          sm={6}
          md={7}
          lg={7}
          alignItems="flex-start"
          className={classes.infoGrid}
        >
          <Typography
            component="h1"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
          >
            Mijn naam is{" "}
            <strong style={{ color: "#3d072d" }}>Emilie Wegman</strong> en sinds
            2009 ben ik werkzaam als fysiotherapeut. Ik heb mezelf
            gespecialiseerd als manueel therapeut (2013) en orofaciaal
            fysiotherapeut (2024).
          </Typography>
          <Typography
            component="span"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
          >
            Na ruim 12 jaar werkervaring bij diverse collega's ben ik sinds mei
            2021 praktijkhouder van mijn eigen kleinschalige praktijk in het
            Ontmoetingscentrum in Nieuw-Vennep.
          </Typography>
          <Typography
            component="span"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
          >
            Hier behandel ik allerlei soorten pijn- en of beweegklachten met als
            uitgangspunt om het lichaam als geheel te bekijken en beweegpatronen
            te herkennen.
          </Typography>
          <Typography
            component="span"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
          >
            U staat hierbij centraal, aan de hand van uw informatie over de
            klacht komen we tot een gericht lichamelijk onderzoek. Met uw
            wensen, mijn expertise en de meest recente behandelmethodes bereiken
            we het beste resultaat!
          </Typography>
          <Typography
            component="span"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
          >
            Ik kijk ernaar uit om u te mogen verwelkomen in de praktijk.
          </Typography>

          <Typography
            component="span"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
          >
            {" "}
            <br></br>
            <IconButton href="tel:+31618223861" className={classes.font}>
              <Call className={classes.icons} />
              &nbsp;&nbsp;0618223861
            </IconButton>
            (tijdens openingstijden anders via de website)
          </Typography>

          <Typography
            component="span"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
          >
            <IconButton
              href="mailto:info@boostfysiotherapie.nl?Subject=Afspraak%20annulering/wijziging"
              className={classes.font}
            >
              <MailOutlined className={classes.icons} />
              &nbsp;&nbsp;info@boostfysiotherapie.nl
            </IconButton>
          </Typography>

          <Typography
            component="span"
            color="textPrimary"
            align="left"
            className={classes.boodschap}
          >
            <IconButton href="/" className={classes.font}>
              <Language className={classes.icons} />
              &nbsp;&nbsp;www.boostfysiotherapie.nl
            </IconButton>
          </Typography>
        </Grid>
        <Grid
          container
          item
          direction="column"
          xs={12}
          sm={6}
          md={5}
          lg={5}
          className={classes.imageGrid}
        >
          <Avatar
            alt="Emilie Wegman"
            src={ProfielFoto}
            className={classes.avatar}
          />
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        style={{
          background: "black",
          position: "fixed",
          bottom: "0",
          width: "100%",
        }}
      >
        <Copyright />
      </Grid>
    </div>
  );
}

export default OverDeTherapeut;
